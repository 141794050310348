import { SpatialReference, Extent } from "@arcgis/core/geometry";
import {
    addSublayersFullExtent,
    setVisibleSubLayers,
} from "../map/MapServiceFactoryUtils";
import Color from "@arcgis/core/Color";

export enum Dimension {
    Only2D = "2D",
    Only3D = "3D",
    Both = "Both",
}
export enum ServiceType {
    MapImageLayer = "map-image",
    WMSLayer = "wms",
    FeatureLayer = "feature",
    VectorTileLayer = "vector-tile",
    TileLayer = "tile",
    PortalItem = "portal-item",
    GraphicsLayer = "graphic",
    ImageryLayer = "imagery",
    Scene = "scene",
    WMSTLayer = "wmts",
    ElevationLayer = "elevation",
    FeatureService = "feature-service",
    GroupLayer = "group",
}

export enum UILocation {
    PilotData = "pilot_data",
}

export interface ConfigMapLayer {
    id?: string;
    title?: string;
    dimension?: Dimension;
    type: ServiceType;
    onLoadCallback?: Function;
    uiLocation?: UILocation;
    sublayerVisibility?: boolean; // Only for GroupLayer
    properties:
        | __esri.MapImageLayerProperties
        | __esri.WMSLayerProperties
        | __esri.FeatureLayerProperties
        | __esri.VectorTileLayerProperties
        | __esri.TileLayerProperties
        | __esri.PortalItemProperties
        | __esri.GraphicsLayerProperties
        | __esri.ImageryLayerProperties
        | __esri.SceneLayerProperties
        | __esri.WMTSLayerProperties
        | __esri.ElevationLayerProperties
        | __esri.GroupLayerProperties;
}

interface AppConfig {
    app: {
        clientSecret: string;
        portalUrl: string;
        apiUrl: string;
    };
    ui: {
        maxMobileWidth: number;
    };
    map2D: {
        spatialReference: __esri.SpatialReference;
        default: {
            basemap: ConfigMapLayer[];
            extent: __esri.Extent;
            zoom: number;
        };
    };
    map3D: {
        spatialReference: __esri.SpatialReference;
        default: {
            basemap: ConfigMapLayer[];
            ground: {
                layers: ConfigMapLayer[];
                navigationConstraint: __esri.GroundNavigationConstraintProperties;
                surfaceColor?: __esri.Color;
                opacity?: number;
            };
            extent?: __esri.Extent;
            zoom?: number;
        };
    };
    layers: Array<ConfigMapLayer>;
    projectPlanning: {
        metadataTableUrl: string;
        attributeHierarchy: string[];
    };
}

const spatialReference = new SpatialReference({ wkid: 25833 });
const initExtent = new Extent({
    xmin: 570493,
    ymin: 7590411,
    xmax: 522800,
    ymax: 7617162,
    spatialReference: spatialReference,
});

const appConfig: AppConfig = {
    app: {
        clientSecret: "M0yaQbaf5pMabH50",
        portalUrl: "https://agek.haehre.cloudgis.no/enterprise",
        apiUrl: window.HAERE_GIS_APP.app_apiUrl,
    },
    ui: {
        maxMobileWidth: 500,
    },
    map2D: {
        spatialReference: spatialReference,
        default: {
            basemap: [
                {
                    type: ServiceType.VectorTileLayer,
                    properties: {
                        url: "https://services.geodataonline.no/arcgis/rest/services/GeocacheVector/GeocacheGraatone/VectorTileServer",
                    } as __esri.VectorTileLayerProperties,
                },
            ],
            extent: initExtent,
            zoom: 8,
        },
    },
    map3D: {
        spatialReference: spatialReference,
        default: {
            basemap: [
                {
                    type: ServiceType.VectorTileLayer,
                    properties: {
                        url: "https://services.geodataonline.no/arcgis/rest/services/GeocacheVector/GeocacheGraatoneTerreng/VectorTileServer",
                    } as __esri.VectorTileLayerProperties,
                },
            ],
            ground: {
                layers: [
                    {
                        type: ServiceType.ElevationLayer,
                        properties: {
                            url: "https://services.geodataonline.no/arcgis/rest/services/Geocache_UTM33_EUREF89/GeocacheTerreng/ImageServer",
                        } as __esri.ElevationLayerProperties,
                    },
                ],
                navigationConstraint: { type: "none" },
                surfaceColor: new Color([0, 0, 0]),
                opacity: 1,
            },
            extent: initExtent,
            zoom: 8,
        },
    },
    layers: [
        // add to map by order
        {
            type: ServiceType.WMSLayer,
            dimension: Dimension.Both,
            title: "Drone WMS", // from service
            onLoadCallback: (layer: __esri.WMSLayer) => {
                setVisibleSubLayers(layer);
                addSublayersFullExtent(layer);
            },
            properties: {
                title: "Drone WMS",
                url: "https://he-wms1.azurewebsites.net/geoserver/28235dfa-a74d-4bc5-8344-81295a755624/wms",
                visible: false,
                customVisibleSublayers: [],
            } as __esri.WMSLayerProperties,
        },
        {
            id: "app_graphics",
            type: ServiceType.GraphicsLayer,
            dimension: Dimension.Both,
            title: "App graphics",
            properties: {
                id: "app_graphics",
                title: "App graphics",
                visible: true,
            } as __esri.GraphicsLayerProperties,
        },
        {
            id: "center_line",
            type: ServiceType.GroupLayer,
            dimension: Dimension.Only2D,
            title: "Senterlinje",
            properties: {
                id: "center_line",
                title: "Senterlinje",
                portalItem: {
                    id: "72440060fde74a6ca7c157517908fd05",
                },
                visible: true,
            } as __esri.GroupLayerProperties,
            sublayerVisibility: true,
        },
        {
            id: "E10HL_Møteplasser",
            type: ServiceType.GroupLayer,
            dimension: Dimension.Only2D,
            title: "E10HL Møteplasser",
            uiLocation: UILocation.PilotData,
            properties: {
                id: "E10HL_Møteplasser",
                title: "E10HL Møteplasser",
                portalItem: {
                    id: "c9e252d797db47ecaa25c1b91dbc27c4",
                },
                visible: false,
                visibilityMode: "independent",
            } as __esri.GroupLayerProperties,
            sublayerVisibility: false,
        },
        {
            id: "E10HL Konstruksjonsnavn",
            type: ServiceType.GroupLayer,
            dimension: Dimension.Only2D,
            title: "E10HL Konstruksjonsnavn",
            uiLocation: UILocation.PilotData,
            properties: {
                id: "E10HL Konstruksjonsnavn",
                title: "E10HL Konstruksjonsnavn",
                portalItem: {
                    id: "7f2db6717f9e4ba6b18316ff4f8ff1f5",
                },
                visible: false,
                visibilityMode: "independent",
            } as __esri.GroupLayerProperties,
            sublayerVisibility: false,
        },
        {
            id: "E10HL Rigg Marksikringsplan",
            type: ServiceType.GroupLayer,
            dimension: Dimension.Only2D,
            title: "E10HL Rigg Marksikringsplan",
            uiLocation: UILocation.PilotData,
            properties: {
                id: "E10HL Rigg Marksikringsplan",
                title: "E10HL Rigg Marksikringsplan",
                portalItem: {
                    id: "225ee6e3c28145f6acdc51fdfcaf1ab5",
                },
                visible: false,
                visibilityMode: "independent",
            } as __esri.GroupLayerProperties,
            sublayerVisibility: false,
        },
        {
            id: "E10HL Va og EL kanstadbotn",
            type: ServiceType.GroupLayer,
            dimension: Dimension.Only2D,
            title: "E10HL Va og EL kanstadbotn",
            uiLocation: UILocation.PilotData,
            properties: {
                id: "E10HL Va og EL kanstadbotn",
                title: "E10HL Va og EL kanstadbotn",
                portalItem: {
                    id: "ca40d8d705d14bf1a89113d32183ade3",
                },
                visible: false,
                visibilityMode: "independent",
            } as __esri.GroupLayerProperties,
            sublayerVisibility: false,
        },
        {
            id: "E10HL YM Plassering fremmede arter",
            type: ServiceType.GroupLayer,
            dimension: Dimension.Only2D,
            title: "E10HL YM Plassering fremmede arter",
            uiLocation: UILocation.PilotData,
            properties: {
                id: "E10HL YM Plassering fremmede arter",
                title: "E10HL YM Plassering fremmede arter",
                portalItem: {
                    id: "7b78dcdc64e847889f1ea1648b2c74b8",
                },
                visible: false,
                visibilityMode: "independent",
            } as __esri.GroupLayerProperties,
            sublayerVisibility: false,
        },
        {
            id: "YM Fremmede arter Masser",
            type: ServiceType.GroupLayer,
            dimension: Dimension.Only2D,
            title: "YM Fremmede arter Masser",
            uiLocation: UILocation.PilotData,
            properties: {
                id: "YM Fremmede arter Masser",
                title: "YM Fremmede arter Masser",
                portalItem: {
                    id: "245635f0531947fca1ac7ec5a5cee720",
                },
                visible: false,
                visibilityMode: "independent",
            } as __esri.GroupLayerProperties,
            sublayerVisibility: false,
        },
        {
            id: "Elektro Lofastkryss",
            type: ServiceType.GroupLayer,
            dimension: Dimension.Only2D,
            title: "Elektro Lofastkryss",
            uiLocation: UILocation.PilotData,
            properties: {
                id: "Elektro Lofastkryss",
                title: "Elektro Lofastkryss",
                portalItem: {
                    id: "d2b76167c6104af39f2ae8ead766eb03",
                },
                visible: false,
                visibilityMode: "independent",
            } as __esri.GroupLayerProperties,
            sublayerVisibility: false,
        },
        {
            id: "Fagmodeller - grunnlag",
            type: ServiceType.GroupLayer,
            dimension: Dimension.Only2D,
            title: "Fagmodeller - grunnlag",
            uiLocation: UILocation.PilotData,
            properties: {
                id: "Fagmodeller - grunnlag",
                title: "Fagmodeller - grunnlag",
                portalItem: {
                    id: "4bf29b26af4a4e81bf88f5522df71b71",
                },
                visible: false,
                visibilityMode: "independent",
            } as __esri.GroupLayerProperties,
            sublayerVisibility: false,
        },
        {
            id: "E10HL Hogstgrense",
            type: ServiceType.GroupLayer,
            dimension: Dimension.Only2D,
            title: "E10HL Hogstgrense",
            uiLocation: UILocation.PilotData,
            properties: {
                id: "E10HL Hogstgrense",
                title: "E10HL Hogstgrense",
                portalItem: {
                    id: "ba47ca0a62104ae8b26ba2db9d8b48b7",
                },
                visible: false,
                visibilityMode: "independent",
            } as __esri.GroupLayerProperties,
            sublayerVisibility: false,
        },
        {
            id: "E10HL motfylling steg1",
            type: ServiceType.GroupLayer,
            dimension: Dimension.Only2D,
            title: "E10HL motfylling steg1",
            uiLocation: UILocation.PilotData,
            properties: {
                id: "E10HL motfylling steg1",
                title: "E10HL motfylling steg1",
                portalItem: {
                    id: "17f539b027a54d42b27c81e46996760d",
                },
                visible: false,
                visibilityMode: "independent",
            } as __esri.GroupLayerProperties,
            sublayerVisibility: false,
        },
        {
            id: "E10HL Trafikkavvikling",
            type: ServiceType.GroupLayer,
            dimension: Dimension.Only2D,
            title: "E10HL Trafikkavvikling",
            uiLocation: UILocation.PilotData,
            properties: {
                id: "E10HL Trafikkavvikling",
                title: "E10HL Trafikkavvikling",
                portalItem: {
                    id: "97dd1347edb749ed933a763692652680",
                },
                visible: false,
                visibilityMode: "independent",
            } as __esri.GroupLayerProperties,
            sublayerVisibility: false,
        },
        {
            id: "Befaring grunneiere",
            type: ServiceType.GroupLayer,
            dimension: Dimension.Only2D,
            title: "Befaring grunneiere",
            uiLocation: UILocation.PilotData,
            properties: {
                id: "Befaring grunneiere",
                title: "Befaring grunneiere",
                portalItem: {
                    id: "3944f00987124565bb383ab7319c6e11",
                },
                visible: false,
                visibilityMode: "independent",
            } as __esri.GroupLayerProperties,
            sublayerVisibility: false,
        },
        {
            id: "Fastmerker",
            type: ServiceType.GroupLayer,
            dimension: Dimension.Only2D,
            title: "Fastmerker",
            uiLocation: UILocation.PilotData,
            properties: {
                id: "Fastmerker",
                title: "Fastmerker",
                portalItem: {
                    id: "0ff4574eafc24855b62859af780602ae",
                },
                visible: false,
                visibilityMode: "independent",
            } as __esri.GroupLayerProperties,
            sublayerVisibility: false,
        },
        {
            id: "Feltinnmalinger",
            type: ServiceType.GroupLayer,
            dimension: Dimension.Only2D,
            title: "Feltinnmalinger",
            uiLocation: UILocation.PilotData,
            properties: {
                id: "Feltinnmalinger",
                title: "Feltinnmalinger",
                portalItem: {
                    id: "3f097130dc304fc7a0ad64d5aa712d9b",
                },
                visible: false,
                visibilityMode: "independent",
            } as __esri.GroupLayerProperties,
            sublayerVisibility: false,
        },
        {
            id: "Regplan",
            type: ServiceType.GroupLayer,
            dimension: Dimension.Only2D,
            title: "Regplan",
            uiLocation: UILocation.PilotData,
            properties: {
                id: "Regplan",
                title: "Regplan",
                portalItem: {
                    id: "b4b807014d6043a08feffa5891597b1c",
                },
                visible: false,
                visibilityMode: "independent",
            } as __esri.GroupLayerProperties,
            sublayerVisibility: false,
        },
        {
            id: "Arealoppfølging",
            type: ServiceType.GroupLayer,
            dimension: Dimension.Only2D,
            title: "Arealoppfølging",
            uiLocation: UILocation.PilotData,
            properties: {
                id: "Arealoppfølging",
                title: "Arealoppfølging",
                portalItem: {
                    id: "b6fa4484f9284c2daa92442133e6457e",
                },
                visible: false,
                visibilityMode: "independent",
            } as __esri.GroupLayerProperties,
        },
    ],
    projectPlanning: {
        metadataTableUrl: window.HAERE_GIS_APP.projectPlanning_metadataTableUrl,
        attributeHierarchy: [
            "wbs",
            "category",
            "partition",
            "type",
            "theme",
            "name",
        ],
    },
};

export default appConfig;
